<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="me-2 d-inline-block">
                <i class="uil uil-money-insert display-6 text-primary"></i>
              </div>
              <div class="mb-2 mb-lg-0 d-inline-block">
                <h4 class="fw-medium font-size-20 text-dark mb-0">
                  {{ title }} <span v-if="pageInfo.payout_id">#{{ pageInfo.payout_id }}</span>
                </h4>
              </div>

              <div class="ms-auto d-flex justify-content-end" v-if="!loading">
                <button
                  type="button"
                  class="btn btn-outline-info font-size-15 rounded fw-medium me-2"
                  :disabled="loading"
                  @click="closeTab()"
                >
                  <i class="uil uil-times"></i>  Close
                </button>
                <div v-if="account_type == 'admin' && create_modify_permission" class="">
                      <span v-if="pageInfo.process_by == 'system'" class="text-start text-muted"
                        >Proccesing by System</span
                      >
                      <button
                        type="button"
                        v-else-if="pageInfo.status == 'pending'"
                        class="btn btn-outline-info font-size-15 rounded fw-medium"
                        :disabled="loading"
                        @click="updatePayout(pageInfo)"
                      >
                        <i class="uil uil-upload"></i>  Action
                      </button>
                      <button
                        type="button"
                        v-else-if="
                          !loading && 
                          pageInfo.status == 'reserved' &&
                          pageInfo.processed_by == accessUsername
                        "
                        class="btn btn-outline-info font-size-15 rounded fw-medium"
                        ref="triggerUpdatePayout"
                        :disabled="loading"
                        @click="updatePayout(pageInfo)"
                      >
                        <i class="uil uil-upload"></i> Action
                      </button>
                      
                      <span v-else class="text-start text-muted fw-normal">
                       <button
                        type="button"
                        class="btn btn-outline-info font-size-15 rounded fw-medium" 
                        disabled
                      >
                      <i class="uil uil-upload"></i> Only allowed for {{ pageInfo.processed_by}}
                      </button>
                       </span
                      >
                </div>
              </div>
            </div>
          </div>

          <div class="card-body pt-1">
            <div v-if="loading">
              <div class="text-center">
                <div
                  class="spinner-border text-primary my-3"
                  v-if="loading"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            
            <div class="p-0 py-2 text-muted" v-if="pageInfo.payout_id">
              <div>
                <div class="row">
                <div class="col-lg-6">
                  <div class="card border">
                      <div class="card-body py-2">
                          <div class="">
                              <div class="d-flex align-items-center">
                                  <div class="avatar align-self-center me-2">
                                      <i class="uil uil-user font-size-24 text-primary"></i>
                                  </div>
                                  <div class="flex-1">
                                      <small class="font-size-14"> #{{  pageInfo.merchant_info.merchant_id }}</small>
                                      <h5 class="font-size-15 mb-1">  {{  pageInfo.merchant_info.merchant_name }}</h5>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="card-body py-2 border-top">
                          <div class="">
                              <div class="d-flex align-items-center">
                                  <div class="avatar align-self-center me-2">
                                      <i class="uil uil-book font-size-24 text-primary"></i>
                                  </div>
                                  <div class="flex-1">
                                      <small class="font-size-12 text-muted d-block mb-1"> Contract Detail</small>
                                      <h5 class="font-size-15 mb-0"> {{  pageInfo.merchant_info.merchant_contract }}: {{  pageInfo.merchant_info.merchant_contract_name }}</h5>
                                      <div class="font-size-13 mb-1">Currency: <span class="text-primary fw-medium">{{  pageInfo.contract_currency }}</span></div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="card border">
                    <div class="card-body">
                          
                          <div class="mb-2">
                          <small class="font-size-13">Reference No.</small>
                          <h5 class="font-size-15 mb-1">  {{ pageInfo.reference_no }}</h5>
                          </div>
                          <div class="mb-2">
                          <small class="font-size-13">Merchant Item ID</small>
                          <h5 class="font-size-15 mb-1">  {{  pageInfo.merchant_item_id  || '-'}}</h5>
                          </div>
                          <div class="mb-2" v-if="account_type=='admin' && pageInfo.pg_info.pg_name">
                            <small class="font-size-13">Payment Gateway</small>
                            <h5 class="font-size-15 mb-1">  {{  pageInfo.pg_info.pg_name  || '-'}}</h5>
                            <div clas="my-1">
                              <button
                                class="btn btn-sm btn-outline-info"
                                @click="
                                  clickExtraInfo(
                                    `${pageInfo.pg_info.pg_name} - Callback Data`,
                                    pageInfo.info.pg_info
                                  )
                                "
                              >
                                Callback Data
                              </button>
                            </div>
                          </div>
                          <div v-else-if="account_type=='admin'" class="mb-2">
                            <small class="font-size-13">Payment Gateway</small>
                            <h5 class="font-size-15 mb-1">-</h5>
                          </div>
                          <div class="mb-2">
                          <small class="font-size-13">Callback Url</small>
                          <h5 class="font-size-15 mb-1">  {{  pageInfo.merchant_callback_url  || '-'}}</h5>
                          </div>
                          <hr>
                          <div class="mb-2">
                          <small class="font-size-13">Receiving Bank</small>
                          <h5 class="font-size-15 mb-1">  {{  pageInfo.receiving_bank }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Beneficiary Account Name</small>
                            <h5 class="font-size-15 mb-1">{{ pageInfo.receiver_account_name }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Beneficiary Account No.</small>
                            <h5 class="font-size-15 mb-1">{{ pageInfo.receiver_account_number }}</h5>
                          </div>
                          <div v-if="pageInfo.extra_field_1 && pageInfo.extra_field_2 && pageInfo.extra_field_3">
                            <div class="mb-2" v-if="pageInfo.extra_field_1.enabled">
                              <small class="font-size-13">{{ pageInfo.extra_field_1.name }}</small>
                              <h5 class="font-size-15 mb-1">{{ pageInfo.extra_field_1.value }}</h5>
                            </div>

                            <div class="mb-2" v-if="pageInfo.extra_field_2.enabled">
                              <small class="font-size-13">{{ pageInfo.extra_field_2.name }}</small>
                              <h5 class="font-size-15 mb-1">{{ pageInfo.extra_field_2.value }}</h5>
                            </div>

                            <div class="mb-2" v-if="pageInfo.extra_field_3.enabled">
                              <small class="font-size-13">{{ pageInfo.extra_field_3.name }}</small>
                              <h5 class="font-size-15 mb-1">{{ pageInfo.extra_field_3.value }}</h5>
                            </div>
                          </div>
                          <hr>
                          <div class="mb-3">
                            <small class="font-size-13 mb-1 d-block">Transaction Description</small>
                            <textarea rows="5" class="form-control font-size-14 text-muted w-100" v-model="pageInfo.transaction_description" readonly></textarea>
                          </div>
                          
                      </div>
                   </div>
                </div>
                <div class="col-lg-6">
                  <div class="card border">
                        <div class="card-body">
                          <div class="mb-2">
                          <small class="font-size-13">Payout ID</small>
                          <h5 class="font-size-15 mb-1"> #{{ pageInfo.payout_id }}</h5>
                          </div>
                          <div class="mb-2">
                          <small class="font-size-13">Initiator</small>
                          <h5 class="font-size-15 mb-1">  <span class="badge bg-soft-purple font-size-13 mt-1 text-capitalize">  {{ pageInfo.initiator }} </span></h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Payout Amount</small>
                            <h5 class="font-size-15 mb-1">{{ pageInfo.contract_currency }} {{ convertCurrencyFormat(pageInfo.payout_amount,true) }}</h5>
                          </div>

                          <div class="mb-2">
                            <small class="font-size-13">Total Charges</small>
                            <h5 class="font-size-15 mb-1">{{ pageInfo.contract_currency }} {{  convertCurrencyFormat(pageInfo.payout_total_charges,true) }} <small class="fw-normal text-muted">({{  pageInfo.payout_rate }}% +
                              {{  pageInfo.contract_currency }} {{  pageInfo.payout_extra_charges}})</small></h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Amount Deducted</small>
                            <h5 class="font-size-15 mb-1">{{ pageInfo.contract_currency }} {{  convertCurrencyFormat(pageInfo.amount_deducted,true) }}</h5>
                          </div>
                          <hr>
                          <div class="mb-2">
                            <small class="font-size-13">Transaction ID</small>
                            <h5 class="font-size-15 mb-1">{{ pageInfo.transaction_id || '-'}}</h5>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13">Transaction Receipt(s)</small>
                            <div class="font-size-14 mb-1" v-if="pageInfo.transaction_receipt.length">
                              <span v-for="(v, i) in pageInfo.transaction_receipt" :key="i" class="d-block">
                              <a
                                  v-if="v !==''"
                                  :href="v"
                                  target="_blank"
                                  title="View Receipt"
                                  ><i
                                    class="uil uil-receipt-alt font-size-16 text-primary"
                                  ></i>
                                  View Receipt ({{ i+1 }})</a
                                >
                              </span>
                            </div>
                            <div v-else>-</div>
                          </div>
                          <div class="mb-2">
                            <small class="font-size-13 mb-1 d-block">Transaction Remarks</small>
                            <textarea rows="3" class="form-control font-size-14 text-muted w-100" v-model="pageInfo.transaction_remark" readonly v-if="pageInfo.transaction_remark"></textarea>
                            <div class="text-muted" v-else>-</div>
                          </div>
                          <div class="mb-3">
                            <small class="font-size-13">Status</small>
                            <h5 class="font-size-15 mt-1" v-if="pageInfo.status == 'complete'">
                              <span class="badge bg-soft-success fw-medium font-size-14">Completed</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="pageInfo.status == 'rejected'">
                              <span class="badge bg-soft-danger fw-medium font-size-14">Rejected</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="pageInfo.status == 'pending'">
                              <span class="badge bg-soft-warning fw-medium font-size-14">Pending</span>
                            </h5>
                            <h5 class="font-size-15 mt-1" v-else-if="pageInfo.status == 'reserved'">
                              <span class="badge bg-soft-info fw-medium font-size-14">Reserved</span>
                            </h5>
                            <textarea v-if="pageInfo.status == 'rejected'" rows="3" class="text-muted form-control" v-model="pageInfo.reason" readonly></textarea>
                          </div>
                          <hr>
                          <div class="font-size-12">Created Date: {{  convertDateByCountry(pageInfo.created_at) }}</div>
                          <div class="font-size-12">Updated Date: {{  convertDateByCountry(pageInfo.updated_at|| '-')  }}</div>
                          <div class="mt-2 font-size-12" v-if="account_type=='admin'">Processed By {{   pageInfo.processed_by  || '-'}}</div>
                        </div>
                   </div>
                </div>
                <div class="col-12">  
                    
                </div>
              
              </div>
            </div> 
          </div>
                
          </div>
        </div>
      </div>
    </div>
    <Lottie
      :path="'/animate/loading_purple.json'"
      :title="'Loading'"
      :info="'Please wait...'"
      :show="showLottie"
      ref="lottieFunc"
    />
    <Common ref="commonFunc" />
    <UpdatePayout
      ref="modalFunc3"
      :data="{
        accessUsername: accessUsername,
        assessToken: accessToken,
        updateData: updateData,
        isInHouse: true
      }"
      @callParentFunction="triggerBackFromParent()"
    />
    <extraInfo ref="modalFunc4" :data="{ extraInfo: currentClickedInfo }" :timezone="selectedTimezone" />
    <ImportPayout
      ref="modalFunc2"
      :data="{ accessUsername: accessUsername, assessToken: accessToken }"
      @callParentFunction="triggerBackFromParent()"
    />
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import UpdatePayout from "@/views/pages/app/modal/updatePayout";
import ImportPayout from "@/views/pages/app/modal/importPayout";
import extraInfo from "@/views/pages/app/modal/extraInfo";
/**
 * Page
 */
const PageTitle = "Payout";
const CountryDefault = { name: "All Countries", value: "", status: "" };
const MerchantDefault = {
  status: "0",
  payin_rate: 0,
  contract_id: "",
  payout_rate: 0,
  total_payin: 0,
  ewallet_rate: 0,
  total_payout: 0,
  contract_name: "All Contracts",
  contract_total: 0,
  contract_status: "",
  settlement_rate: 0,
  contract_balance: 0,
  total_settlement: 0,
  contract_currency: "",
  payin_extra_charges: 0,
  payout_extra_charges: 0,
  ewallet_extra_charges: 0,
  settlement_extra_charges: 0,
  contract_list_length_total: 0,
  merchant_Id: "",
  account_name: "",
  merchant_status: "",
  reseller_id: "",
  reseller_name: "",
  reseller_code: "",
  reseller_level: 0,
};
export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
    UpdatePayout,
    ImportPayout,
    extraInfo
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  data() {
    return {
      currentClickedInfo: { title: "", info: {}, type: ""},
      pageInfo: {},
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      account_type: "",
      showLottie: false,
      title: "",
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: "",
          active: true,
        },
      ],
      formData: {
        apiID: "YOUR_API_ID",
        apiHash: "YOUR_API_HASH",
      },
      selectedTimezone: "Malaysia",
      timezoneCountry:appConfig.timezoneCountryList,
      updateData: {},
      submitted: false,
      pageLoading:false,
      loading: false,
      buttonLoading: [false, false, false],
      goToPage: "",
      currentPage: 1,
      currencySymbol: "₹",
      selectedmerchantContract: MerchantDefault,
      merchantContract: [MerchantDefault],
      selectedCountry: CountryDefault,
      countryList: [CountryDefault],
      seletedMethod: "",
      selectedStatus: "",
      searchPayoutId: "",
      searchReferenceNo: "",
      searchUserID: "",
      searchBankAccountNo: "",
      filterType: "created_date",
      searchAmount: "",
      perPage: 20,
      totalData: 0,
      returnData: [],
      returnPaidTotal: 0,
      returnPayoutProcessed: 0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      searchAmountType1:{label:"Less Than", value:"lessThan"},
      searchAmountType1Value:"",
      searchAmountType2:{label:"Equal To", value:"equalTo"},
      searchAmountType2Value:"",
      searchAmountType3:{label:"More Than", value:"moreThan"},
      searchAmountType3Value:"",
      searchAmountTypeList: [{label:"Less Than", value:"lessThan"},{label:"Equal To", value:"equalTo"},{label:"More Than", value:"moreThan"}],
      date_range: null,
      disable_date: false,
      disabled: false,
      shortcuts: [],
      selectedMerchant: "M00001",
      currentMerchantInfo:{},
      currentResellerInfo:{},
      create_modify_permission:false
    };
  },
  middleware: "authentication",
  async mounted() {
    this.title = PageTitle;
    this.items[1].text = PageTitle;
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload();
    this.accessToken = this.$refs.commonFunc.getToken();
    this.accessUsername = this.$refs.commonFunc.getUsername();
    this.triggerBackFromModal = false;
    this.getPayoutDetail();
  
  },
  created() {},
  methods: {
    closeTab() {
      window.close();
    },
    clickExtraInfo(title, object, type) {
      this.currentClickedInfo = { title: "", info: {}, type:"" };
      this.currentClickedInfo = { title: title, info: object, type: type};
      setTimeout(() => this.$refs.modalFunc4.showExtraInfoModal(), 0);
    },
    // convertDateByCountry(dateTimeString, country) {
    //   return this.$refs.commonFunc.convertDateByCountry(dateTimeString, country);
    // },
    convertDateByCountry(dateTimeString) {
        console.log(dateTimeString)
        if (dateTimeString==null){
          return "-"
        }else{
          return this.$refs.commonFunc.convertDateByCountry(dateTimeString, this.timezone);
        }
    },
    updatePayout(value) {
      this.updateData = value;
      setTimeout(() => this.$refs.modalFunc3.showModal(), 100);
    },
    convertCurrencyFormat(value, show00) {
      if ( this.$refs.commonFunc){
        if (show00 == true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value);
        } else {
          // Assuming this.transactionLimit is defined in your component's data
          return this.$refs.commonFunc
            .convertCurrencyFormat(value)
            .replaceAll(".00", "");
        }
      }
    },
    triggerBackFromParent(){
      this.triggerBackFromModal = true;
      this.getPayoutDetail();
    },
    getPayoutDetail() {
      console.log("getPayoutDetail");
      this.$Progress.start();
      this.loading = true;
      this.disabled = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("payoutDbCode", this.$route.params.id);
      axios({
        method: "post",
        url: appConfig.APIHostAdmin +  "controller/admin/getPayoutDetails",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            if (resData.data.length > 0) {
              
              this.pageInfo =  resData.data[0]
              document.title = `Payout #${resData.data[0].payout_id} | ${this.items[0].text}`;
              // if (this.triggerBackFromModal && resData.data[0].status=='complete'){
              //   this.closeTab();
              //   //if use closetab function you need to manual refresh the payout pending page
              // }
              if (this.pageInfo.status == 'reserved' && this.pageInfo.processed_by == this.accessUsername){
                 setTimeout(() => this.$refs.triggerUpdatePayout.click(),0)
              }
            }
            //window.close();
          } 
          else if (resData.status == 400) {
            this.$router.push({
              name: "in-house payouts",
            });
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.disabled = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.currentMerchantInfo = data.merchant_info;
          this.currentResellerInfo = data.reseller_info;
          this.account_type = data.account_type;
          this.selectedTimezone = data.time_zone;
          const permission = data.modules.filter(e => e.name==='payout')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "payout" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  },
};
</script>
<style scoped>
.form-label{font-size: 12px;  }
</style>